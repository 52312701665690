/**
 * Konstant med standard score og spillernavn
 *  */

const DEFAULT_SCORE = [
  { Name: 'Spiller 1', Score: 0 },
  { Name: 'CPU', Score: 0 }
];

export default DEFAULT_SCORE;
